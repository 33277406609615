import { InjectionToken } from '@angular/core';
import { QueryOptions } from '@apollo/client/core';
import { Observable } from 'rxjs';

export interface IntlOptions {
  query: <T>(options: QueryOptions) => Observable<T>;
  locale$: Observable<string>;
  setLocale: (locale: string) => void | Promise<void>;
  tenantId$: Observable<number | undefined>;
}

export const INTL_OPTIONS = new InjectionToken<IntlOptions>('IntlOptions');
