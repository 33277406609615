import { ChangeDetectorRef, OnDestroy, Pipe, PipeTransform, untracked } from '@angular/core';
import { Observable, Observer, Subscription } from 'rxjs';
import { IntlService } from '../services/intl.service';

@Pipe({
  name: 'intl',
  pure: false,
})
export class IntlPipe implements PipeTransform, OnDestroy {
  private subscription?: Subscription;

  private _ident?: string;
  private _value?: string;

  private get value(): string | undefined {
    return this._value;
  }

  private set value(value: string | undefined) {
    this._value = value;
    this.changeRef.markForCheck();
  }

  constructor(private changeRef: ChangeDetectorRef, private service: IntlService) {}

  transform(name: string | undefined, options?: { params?: { [name: string]: unknown }; default?: string }): string {
    const ident = `${name}${JSON.stringify(options)}`;

    if (!this.subscription || this._ident !== ident) {
      this._ident = ident;

      this.unsubscribe();
      this.subscribe(this.service.getTranslation(name, options?.params), {
        next: (value) => (this.value = value),
        error: (e) => {
          console.error('Intl pipe translation error', e);

          this.unsubscribe();
          this.value = undefined;
        },
      });
    }

    return this.value ?? (options && Object.prototype.hasOwnProperty.call(options, 'default') ? options.default : '???') ?? '';
  }

  ngOnDestroy(): void {
    this.unsubscribe();
    this._value = undefined;
  }

  private subscribe<T>(observable: Observable<T>, observer: Partial<Observer<T>>) {
    untracked(() => (this.subscription = observable.subscribe(observer)));
  }

  private unsubscribe() {
    untracked(() => {
      this.subscription?.unsubscribe();
      this.subscription = undefined;
    });
  }
}
