import { gql } from '@apollo/client/core';

export const availableLanguagesQuery = gql`
  query AvailableLanguages($tenantId: bigint!) {
    data: intl_languages(where: { tenant_supported_languages: { tenant_id: { _eq: $tenantId } } }, order_by: { name: asc }) {
      code
      name
      angular_code
      cldr_code
    }
  }
`;

export const allLanguagesQuery = gql`
  query AllLanguages {
    data: intl_languages(order_by: { name: asc }) {
      code
      name
      angular_code
      cldr_code
    }
  }
`;

export const translationsUpdateQuery = gql`
  query TranslationsUpdateQuery($locale: String!, $since: timestamptz!) {
    now {
      timestamp
    }
    intl_languages(where: { code: { _eq: $locale } }) {
      translations(where: { updated_at: { _gte: $since } }) {
        name
        translation
      }
      deleted_translations(where: { deleted_at: { _gte: $since } }) {
        name
      }
    }
  }
`;

export const defaultLanguageQuery = gql`
  query DefaultLanguage($tenantId: bigint!) {
    data: identity_tenant_by_pk(id: $tenantId) {
      default_language {
        code
      }
    }
  }
`;
