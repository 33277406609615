export function isNonNullable<T>(val: T): val is NonNullable<T> {
  return !!val;
}

export function isSettledPromiseFulfilled<T>(item: PromiseSettledResult<T>): item is PromiseFulfilledResult<T> {
  return item.status === 'fulfilled';
}

export function isSettledPromiseRejected<T>(item: PromiseSettledResult<T>): item is PromiseRejectedResult {
  return item.status === 'rejected';
}
